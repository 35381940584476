<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <b-form>
      <b-row> 
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Name"
            label-for="user_name"
          >
            <b-form-input
              id="user_name"
              v-model="userData.user_name"
            />
          </b-form-group>
        </b-col> 
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Username"
            label-for="user_username"
          >
            <b-form-input
              id="user_username"
              v-model="userData.user_username"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        > 
          <b-form-group
            label="Password"
            label-for="user_password"
          >
            <b-form-input
              id="user_password"
              v-model="userData.user_password" type="password"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Confirm Password"
            label-for="user_confirm_password"
          >
            <b-form-input
              id="user_confirm_password"
              v-model="userData.user_confirm_password"
              type="password"
            />
          </b-form-group>
        </b-col>
          <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Email"
            label-for="user_email"
          >
            <b-form-input
              id="user_email"
              v-model="userData.user_email"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Mobile"
            label-for="user_mobile"
          >
            <b-form-input
              id="user_mobile"
              v-model="userData.user_mobile"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
        <b-form-group
              label="Status"
              label-for="status"
            >
            <b-form-select
                  v-model="userData.user_Status"
                  :options="statusOptions" 
                />  
            </b-form-group> 
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
        <b-form-group
              label="Role"
              label-for="role"
            >
              <b-form-select
                  v-model="userData.user_role"
                  :options="roleOptions" 
                />  
            
            </b-form-group> 
          </b-col>
      </b-row>
    </b-form>
 
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="saveUser()"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :to="{ name: 'apps-users-list'}"
    >
      Cancel
    </b-button>
    
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable,BCardHeader, BCardTitle, BFormCheckbox,BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    vSelect,
    BFormSelect,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable, 
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  setup() {
    
    const userData = ref({})
    const roleOptions = ref(["User","Admin"]);
    const statusOptions = ref(["Active","Inactive"]);

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    const saveUser = () => {
      let userSessionData = JSON.parse(localStorage.getItem('userData'));
      userData.value.user_updated_by = userSessionData.user_id;

      store.dispatch('app-user/updateUser', userData.value)
      .then(response => {
        // redirect to list
        alert("Saved!")

       })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      }) 
    }


    return {
      roleOptions,
      statusOptions,
      saveUser,
      userData,
    }
  },
}
</script>

<style>

</style>
